import React from "react";
import { Environment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { History } from "../core/history";
const axios = require("axios").default;

type AppProps = {
  history: History;
  relay: Environment;
};
export function MainComponent(): JSX.Element {
  let navigate = useNavigate();

  const [state, setState] = React.useState({
    states: [],
  });

  return (
    <div style={{ display: "flex", flex: 1 }}>
      <div style={{ flex: 1 }}>Coming soon</div>
    </div>
  );
}
