import React from "react";
import toast from "react-hot-toast";
const axios = require("axios").default;

export const QuestionCell = React.memo(({ question, alias }) => {
  const [state, setState] = React.useState({
    question,
    alias,
  });
  const [buttonState, setButtonState] = React.useState({
    title: "Save",
    color: "#F0FFFF",
  });
  const handleAnswerChange = (questionAlias, answer, event) => {
    let answerIndex = alias.answers.indexOf(answer);
    alias.answers[answerIndex].text = event.target.value;
    setState({
      question,
      alias,
    });
  };

  const handleDescChange = (questionAlias, event) => {
    alias.description = event.target.value;
    setState({
      question,
      alias,
    });
  };

  const calculateWordCount = (question) => {
    let qd = question.description.trim().split(/\s+/).length;
    let qe = question.explanation.trim().split(/\s+/).length;
    let ac = question.answers
      .map((a) => {
        return a.text.trim().split(/\s+/).length;
      })
      .reduce((a, b) => a + b, 0);

    return parseInt(qd) + parseInt(qe) + parseInt(ac);
  };
  const handleExplanationChange = (questionAlias, event) => {
    alias.explanation = event.target.value;
    setState({
      question,
      alias,
    });
  };

  const save = (questionAlias) => {
    if (questionAlias != null) {
      axios
        .post(`/updateQuestionAlias`, questionAlias)
        .then(function (response) {
          // handle success
          setButtonState({ title: "Success", color: "#198754" });
          console.log(response);
        })
        .catch(function (error) {
          // handle error
          setButtonState({ title: error, color: "red" });
          console.log(error);
        });
    } else {
      toast.error("Question data is null");
    }
  };

  return (
    <div
      key={question._id}
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: alias.isEdited ? "#00FF0020" : "",
      }}
    >
      <div style={{ flex: 1, border: "1px solid gray" }}>
        <div
          style={{
            margin: 12,
            padding: 12,
            borderColor: "gray",
            borderWidth: 1,
          }}
        >
          id:{question.id}
          {question.imageName !== "" && (
            <img
              onClick={() => {
                navigator.clipboard.writeText(question.imageName);
              }}
              style={{ width: 200, height: 200 }}
              src={`https://thedvani.com/uspdd/${question.imageName}.png`}
            ></img>
          )}
          <div
            onClick={() => {
              navigator.clipboard.writeText(question.description);
            }}
          >
            Description: {question.description}
          </div>
          <div style={{ backgroundColor: "#00000054" }}>
            {question.answers.map((item) => {
              return (
                <div
                  style={{ paddingBottom: 20 }}
                  key={`${item.id}_a`}
                  onClick={() => {
                    navigator.clipboard.writeText(item.text);
                  }}
                >
                  <div
                    style={{
                      margin: 12,
                      backgroundColor: item.isCorrect ? "#00FF0050" : "",
                    }}
                  >
                    {item.isCorrect ? "true " : "false "}Answer id:
                    {item.id}
                    {" =>> "}
                    {item.text}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            style={{ paddingBottom: 24 }}
            onClick={() => {
              navigator.clipboard.writeText(question.explanation);
            }}
          >
            explanation: {question.explanation}
            <br></br>
          </div>
          <div style={{ color: "gray" }}>
            Word Count: {calculateWordCount(question)}
          </div>
        </div>
      </div>
      <div style={{ flex: 1, border: "1px solid gray" }}>
        <div
          style={{
            margin: 12,
            padding: 12,
            borderColor: "gray",
            borderWidth: 1,
          }}
        >
          id:{alias.id}
          {question.imageName !== "" && (
            <img
              onClick={() => {
                navigator.clipboard.writeText(alias.imageName);
              }}
              style={{ width: 200, height: 200 }}
              src={`https://thedvani.com/uspdd/${alias.imageName}.png`}
            ></img>
          )}
          <div style={{ maxWidth: "85%" }}>
            Description: {alias.description}
          </div>
          <textarea
            style={{ width: "85%" }}
            value={alias.description}
            onChange={(e) => handleDescChange(alias, e)}
          />
          <div style={{ backgroundColor: "#00000054" }}>
            {alias.answers.map((item) => {
              return (
                <div key={`${item.id}_bb`}>
                  <div
                    style={{
                      backgroundColor: item.isCorrect ? "#00FF0050" : "",
                    }}
                  >
                    {item.isCorrect ? "true " : "false "}Answer id:
                    {item.id}
                    {" =>> "}
                    {item.text}
                  </div>
                  <textarea
                    style={{ width: "85%" }}
                    value={item.text}
                    onChange={(e) => handleAnswerChange(alias, item, e)}
                  />
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            explanation: {alias.explanation}
            <textarea
              style={{ width: "85%" }}
              value={alias.explanation}
              onChange={(e) => handleExplanationChange(alias, e)}
            />
            <br></br>
          </div>
          <button
            style={{ backgroundColor: buttonState.color, width: 200 }}
            onClick={() => save(alias)}
          >
            {buttonState.title}
          </button>
          <>{alias.isEdited && "isEdited"}</>
        </div>
      </div>
    </div>
  );
});
