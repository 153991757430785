import React, { useEffect } from "react";
import { Environment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { History } from "../core/history";
const axios = require("axios").default;

type AppProps = {
  history: History;
  relay: Environment;
};
export function StatesComponent(): JSX.Element {
  let navigate = useNavigate();

  const [state, setState] = React.useState({
    states: [],
  });

  useEffect(async () => {
    updateTime();
  }, []);

  const updateTime = () => {
    //updating the time by set state

    axios
      .get("/states")
      .then(function (response) {
        // handle success
        setState({ states: response.data.states });
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  return (
    <div>
      <div>
        {state.states.map((item) => {
          return (
            <div
              // onClick={() => navigate(`/state/${item.id}/tests/`)}
              style={{
                padding: 12,
                margin: 12,
                backgroundColor: "gray",
                borderRadius: 1,
                borderColor: "white",
              }}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}
