import React, { useEffect } from "react";
import { Environment } from "react-relay";
import { useLocation, useParams } from "react-router";
import { History } from "../core/history";
import { QuestionCell } from "./QuestionCell";
const axios = require("axios").default;

type AppProps = {
  history: History;
  relay: Environment;
};

export function QuestionsComponent(): JSX.Element {
  const { stateId, testId } = useParams();
  const search = useLocation().search;
  const userParam = new URLSearchParams(search).get("user");
  const allParam = new URLSearchParams(search).get("all");
  const explanationParam = new URLSearchParams(search).get("explanation");
  const descriptionParam = new URLSearchParams(search).get("description");
  const imageNameParam = new URLSearchParams(search).get("imageName");
  const [loading, setLoading] = React.useState({
    status: true,
  });
  const [state, setState] = React.useState({
    items: {
      questions: [],
      questionsAlias: [],
    },
  });

  useEffect(async () => {
    updateTime();
  }, []);

  const totalWords = (questions) => {
    return questions
      .map((q) => {
        return calculateWordCount(q);
      })
      .reduce((a, b) => a + b, 0);
  };
  const calculateWordCount = (question) => {
    let qd = question.description.trim().split(/\s+/).length;
    let qe = question.explanation.trim().split(/\s+/).length;
    let ac = question.answers
      .map((a) => {
        return a.text.trim().split(/\s+/).length;
      })
      .reduce((a, b) => a + b, 0);

    return parseInt(qd) + parseInt(qe) + parseInt(ac);
  };

  const renderQuestions = (items) => {
    return items.questions.map((question) => {
      var alias = items.questionsAlias.filter(
        (item) => item.id === question.id
      )[0];

      return (
        <QuestionCell key={question._id} question={question} alias={alias} />
      );
    });
  };

  const renderBody = () => {
    return (
      <div key={999} style={{ width: "100", border: "1px solid black" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          Questions Count: {state.items.questions.length}
          {" --- "}
          {loading && <div>Please wait {" --- "} </div>}
          Words count: {totalWords(state.items.questions)}
        </div>
        {renderQuestions(state.items)}
      </div>
    );
  };
  const updateTime = () => {
    //updating the time by set state

    axios
      .get(`/states/${stateId}/tests/${testId}/questions`, {
        params: {
          user: userParam,
          imageName: imageNameParam,
          all: allParam,
          description: descriptionParam,
          explanation: explanationParam,
        },
      })
      .then(function (response) {
        setLoading(false);
        // handle success
        setState({
          items: {
            questions: response.data.questions,
            questionsAlias: response.data.questionsAlias,
          },
        });
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        console.log(error);
      });
  };

  return <div style={{ display: "flex" }}>{renderBody()}</div>;
}
