/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */
import { Environment } from "react-relay";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MainComponent } from "./MainComponent";
import { QuestionsComponent } from "./QuestionsComponent";
import { StatesComponent } from "./States";
import { TestsComponent } from "./TestsComponent";
const axios = require("axios").default;
// axios.defaults.baseURL = "http://localhost:8081";
axios.defaults.baseURL =
  "http://dmvrewrite-env-1.eba-i2bhvzat.us-east-1.elasticbeanstalk.com";

type AppProps = {
  history: History;
  relay: Environment;
};

export function App(): JSX.Element {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<MainComponent />} />
          <Route path="/state" element={<StatesComponent />} />
          <Route path="/state/:stateId/tests/" element={<TestsComponent />} />
          <Route
            path="/state/:stateId/tests/:testId"
            element={<QuestionsComponent key={1} />}
          />
        </Routes>
      </Router>
    </>
  );
}
